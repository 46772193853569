.contact-us {
    padding:25% 6% 6% 6%;
    text-align:center;
  }
  
  .contact-us hr {
    margin: auto;
    margin-top: 15px;
  }
  
  .contact-us h1 {
    margin: 0 auto;
    font-size: 3.7rem;
  }

  @media only screen and (max-width: 920px) {
    .contact-us h1 {
      margin: 0 auto;
      font-size: 3rem;
    }

    .contact-us {
      position: relative;
      top: 15vh;
    }
  }

  @media only screen and (max-width: 600px) {
    .contact-us h1 {
      margin: 0 auto;
      font-size: 1.5rem;
    }

    .contact-us {
      padding:30% 6% 6% 6%;
    }
  }