.about {
    padding:10%;
  }

  .about h1 {
    font-size: 3.7rem;
    line-height: 1.1;
    margin-bottom: 10px;
    font-weight: 700;
    margin-top: 0;
    max-width: 900px;
    text-transform: uppercase;
  }

  .about .two {
    font-size: 3.7rem;
    line-height: 1.1;
    font-weight: 100;
    margin-top: 0;
    max-width: 900px;
    text-transform: uppercase;
  }

  @media only screen and (max-width: 870px) {
    .about h1 {
      font-size: 3rem;
    }

    .about .two {
      font-size: 3rem;
    }
  }

  @media only screen and (max-width: 700px) {
    .about h1 {
      font-size: 2.5rem;
    }

    .about .two {
      font-size: 2.5rem;
    }
  }

  @media only screen and (max-width: 500px) {
    .about h1 {
      font-size: 2rem;
    }

    .about .two {
      font-size: 2rem;
    }
  }