.header {
  display: grid;
  grid-template-columns: 6fr 6fr;
  grid-template-rows: 1fr;
  grid-template-areas: ". .";
}

.header-logo {
    width: 100px;
    height: auto;
    top: 30px;
    position: relative;
    left: 40px;
  }

  .header-instagram {
    width: 16px;
    height: 16px;
    top: 32px;
    position: relative;
    right: -30px;
    cursor: pointer;
    float: right;
    fill: #fff !important;;
  }

  .header-top-instagram-text {
    top: 30px;
    position: relative;
    cursor: pointer;
    right: 80px;
    float:right;
    font-weight: 500;
  }

  @media only screen and (max-width: 440px) {
    .header-top-instagram-text {
      display: none;
    }

    .header-instagram {
      right: 30px;
    }
  }