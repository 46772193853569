.join-the-cause-container {
    max-width:200px;
    background-color: #1b2550;
    text-transform:uppercase;
    color: #fff; 
    font-size:16px;
    line-height: 20px;
    font-weight:500;
    border-right: 30px solid #6aff7f;
    letter-spacing: 2px;
    padding-left: 3px;
  }
  
  .join-the-cause-red {
    width: 5px;
    height: 20px;
    background-color:#fb516e;
    float: right;
  }

.hero {
    padding: 15% 10% 10% 10%;
}

.two {
    font-size: 74px;
    line-height: 78px;
    margin-bottom: 40px;
    font-weight: 100;
    margin-top: 0;
    max-width: 900px;
    text-transform: uppercase;
  }

  @media only screen and (max-width: 720px) {
    .hero h1 {
      font-size: 3rem;
    }

    .hero .two {
      font-size: 3rem;
    }
  }

  @media only screen and (max-width: 500px) {
    .hero h1 {
      font-size: 2rem;
    }

    .hero .two {
      font-size: 2rem;
    }

    .hero h2 {
      font-size: 1.5rem;
      line-height: 1.3;
    }
  }