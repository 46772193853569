.findoutmore {
    margin-top:5%;
  }
  
  .findoutmore p {
    display: flex;
    justify-content: center;
    text-transform: uppercase;
    font-weight: 500;
  }
  
  .findoutmore button {
    border: 0;
    margin: 0 auto;
    display:flex;
    background-color: none;
  }

  .findoutmore button:hover {
    background-color: transparent;
  }

  .findoutmore img {
    width: 56px;
    height: 56px;
    margin: 0 auto;
    display: flex;
    cursor: pointer;
  }

  @keyframes bounce {
    from,
    20%,
    53%,
    80%,
    to {
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
      transform: translate3d(0, 0, 0);
    }
  
    40%,
    43% {
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      transform: translate3d(0, -30px, 0);
    }
  
    70% {
      animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
      transform: translate3d(0, -15px, 0);
    }
  
    90% {
      transform: translate3d(0, -4px, 0);
    }
  }
  
  .bounce {
    animation-name: bounce;
    transform-origin: center bottom;
  }

  .animated {
    animation-duration: 1s;
    animation-fill-mode: both;
  }
  
  .animated.infinite {
    animation-iteration-count: infinite;
  }
  
  .animated.delay2s {
    animation-delay: 2s;
  }
  
  @media (print), (prefers-reduced-motion: reduce) {
    .animated {
      animation-duration: 1ms !important;
      transition-duration: 1ms !important;
      animation-iteration-count: 1 !important; 
    }
  }

  @media only screen and (max-width: 720px) {
    .findoutmore {
      margin-top:20%;
    }
  }