.gallery {
    padding: 25% 6% 6% 6%;
    text-align:center;
  }
  
  .gallery hr {
    margin: auto;
    margin-top: 15px;
  }
  
  .gallery-button {
    margin-top: 30px;
    width: 200px;
  }
  
  .gallery h1 {
    font-size:3.7rem;
    margin: 0 auto;
  }

  .gallery img {
    width: 80%;
    height: auto;
  }

  .gallery p {
    max-width: 9w00px;
    margin: 20px auto;
  }

  @media only screen and (max-width: 870px) {
    .gallery h1 {
      font-size: 3rem;
      margin-top: 5%;
    }
  }

  @media only screen and (max-width: 850px) {
    .gallery {
      position: relative;
      top: 15vh;
    }
  }

  @media only screen and (max-width: 650px) {
    .gallery h1 {
      font-size: 2.5rem;
      margin-top: 5%;
    }
  }

  @media only screen and (max-width: 400px) {
    .gallery h1 {
      font-size: 2rem;
      margin-top: 5%;
    }
  }