footer {
    display: flex;
    justify-content: center;
    padding-bottom: 200px;
    text-align: center;
  }
  
  .footer-logo {
    width: 220px;
    height: auto;
    margin: 0 auto;
    text-align: center;
    margin-bottom:40px;
  }

  .instagram-footer-logo {
    width: 16px;
    height: 16px;
    margin:0 auto;
    justify-content: center;
    align-items: center;
  }

  @media only screen and (max-width: 920px) {
    footer {
      position: relative;
      top: 15vh;
      padding-bottom: 200px;
    }
  }