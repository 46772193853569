.footer-logo {
    width: 220px;
    height: auto;
    margin: 0 auto;
    text-align: center;
    margin-bottom:40px;
  }
  
  .instagram-footer-logo {
    width: 16px;
    height: 16px;
    margin:0 auto;
    justify-content: center;
    align-items: center;
  }
  
  @media only screen and (max-width: 920px) {
    footer {
      margin-top:0;
      margin-bottom:100px;
    }
    
  }