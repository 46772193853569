.funds {
  width: 100%;
  margin: 0 auto;
  
}

.container {
  width: 100%;
  min-height: 100vh;
  background-color: #151823;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 33px 30px;
  margin: 0px; 
  padding: 0px; 
  box-sizing: border-box;
  background-image: url('../img/funds-background-top.png');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: contain;
  max-width: 1400px;
  margin: 0 auto;
}

.wrapper h1 {
  font-size: 2.6rem;
}

.instructions {
  margin-top: 100px;
}

.instructions hr {
  margin-bottom: 30px;
}

.instructions strong {
  color: #33cbcc;
}

.location-wrapper {
  margin-top: 80px;
}

.location-wrapper:last-child {
  margin-bottom:100px;
}

.location-wrapper h1 {
  font-size: 2.1rem;
}

h1 span {
  font-size: 3.2rem;
  font-weight:300;
}

.nav {
  margin-top: 100px;
}

.nav-menu {
  float: right;
}

li {
  display: inline;
  margin-right: 15px;
  font-weight: 400;
  text-transform: uppercase;
  cursor: pointer;
}

li a {
  text-decoration: none;
}

a li:hover {
  color: #33cbcc;
}

a {
  text-decoration: underline;
}

.header-top-instagram-text {
  top: 30px;
  position: relative;
  cursor: pointer;
  right: 80px;
  float:right;
  font-weight: 500;
}

.counter {
  background-color: #33cbcc;
  width:40px;
  height:40px;
  border-radius: 50%;
  text-align:center;
  padding: 10px;
  color: #fff;
}

.cell strong {
  color: #fff;
}

.wrapper {
  width: 960px;
  border-radius: 10px;
  overflow: hidden;
  padding: 0 5%;
}

.table {
  width: 100%;
  display: table;
  margin: 0;
}

@media screen and (max-width: 768px) {
  .table {
    display: block;
  }
}

.row {
  display: table-row;
}

@media screen and (max-width: 768px) {
  .row {
    display: block;
  }

  .row.header {
    padding: 0;
    height: 0px;
  }

  .row.header .cell {
    display: none;
  }

  .row .cell:before {
    font-size: 12px;
    line-height: 1.2;
    text-transform: uppercase;
    font-weight: unset !important;

    margin-bottom: 13px;
    content: attr(data-title);
    min-width: 98px;
    display: block;
  }
}

.cell {
  display: table-cell;
}

@media screen and (max-width: 768px) {
  .cell {
    display: block;
  }
}

.row .cell {
  font-size: 15px;
  color: #778fc3;
  line-height: 1.2;
  font-weight: unset !important;

  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #2a3046;
}

.row.header .cell {
  font-size: 18px;
  color: #fff;
  line-height: 1.2;
  font-weight: unset !important;

  padding-top: 19px;
  padding-bottom: 19px;
}

.row .cell:nth-child(1) {
  width: 150px;
  padding-left: 0px;
}

.row .cell:nth-child(2) {
  width: 350px;
  padding-right:20px;
}

.row .cell:nth-child(3) {
  width: 300px;
  padding-right: 30px;
}

.row .cell:nth-child(4) {
  width: 120px;

}

.table, .row {
  width: 100% !important;
}

.fund-img {
    max-width: 220px;
    height: auto;
    width: 100%;
}

footer {
  display: flex;
  justify-content: center;
  margin-top:100px;
  text-align: center;
}

.row button {
    background-color: transparent;
    border: 2px solid #8a8c91;
    color: #8a8c91;
}

.row a {
  text-decoration: none;
}

.row button:hover {
  background-color: #8a8c91;
  border: 2px solid #8a8c91;
  color: #fff;
}

.counter p {
  margin-top: 9px;
}

@media (max-width: 768px) {
  .row {
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 18px;
    padding-top: 30px;
    margin: 0;
  }
  
  .row .cell {
    border: none;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .table, .row, .cell {
    width: 100% !important;
  }
}

@media (max-width: 646px) {
  .instructions h1 {
    font-size: 2rem;
  }
  .instructions h2 {
    font-size: 1.5rem;
    line-height: 1.3;
  }
  .funds .nav-menu {
    float: none;
  }
  .funds .nav-menu ul {
    width: 100%;
  }
  .nav-menu li {
    display:block;
    width: 100%;
    padding: 10px;
  }
  .location-wrapper {
    margin-top:70px;
  }
  .funds .nav {
    margin-top: 70px;
  }
  .location-wrapper:last-child {
    margin-bottom:0;
  }
  .nav-menu ul {
    margin: 0 auto;
    padding: 0;
    margin-top:30px;
  }
  .nav-menu li:hover {
    background-color:#33cbcc;
  }
  .nav-menu a {
    text-decoration:none;
  }
  .nav-menu a:hover {
    color: #fff;
  }
  .nav-menu li {
    display: block;
    width: 50%;
    padding: 10px;
    background-color: #8a8c91;
    margin-bottom: 10px;
    text-align: center;
    margin-left: 0;
    padding-left: 0;
    font-weight: 500;
    margin: 10px auto;
    border-radius: 25px;
  }
}