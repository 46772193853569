body {
  margin: 20px;
  font-family: 'Barlow', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align:left;
  background-color: #0f152c;
  color: #fff;
  font-size: 16px;
  margin: 0;
  background: rgb(238,174,202);
  background: radial-gradient(circle, rgba(238,174,202,1) 0%, rgba(148,187,233,1) 100%);
}

.app {
  background-image: url('./img/background-final.jpg');
  background-size: cover;
  max-width: 1400px;
  margin: 0 auto;
}

h1 {
  font-size: 4.7rem;
  line-height: 1.1;
  margin-bottom: 10px;
  font-weight: 700;
  margin-top: 0;
  max-width: 900px;
  text-transform: uppercase;
}

h2 {
  font-size: 32px;
  line-height: 40px;
  margin-bottom: 23px;
  font-weight: 400;
  max-width: 800px;
  margin-top: auto;
}

h3 {
  font-size: 18px;
  line-height: 25px;
  font-weight: 400;
  max-width: 800px;
  margin-top: auto;
}

p {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
}

button {
  border: 2px solid #b3c5e3;
  background-color: transparent;
  padding: 10px;
  width: 160px;
  text-transform: uppercase;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: #fff;
  cursor: pointer;
  outline: none;
}

button:hover {
  background-color: #b3c5e3;
  color: #0f152c;
}

hr {
  max-width: 50px;
  margin: 0 0 10px 0;
  border: 1px solid #33cbcc;
}

a {
  text-decoration: none;
  color: #fff;
}
