.instructions {
    padding: 20% 10% 10% 10%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 50px;
    grid-row-gap: 0px;
  }
  
  .instructions button {
    margin-top: 20px;
    border: solid 2px #000;
    color: #000;
    font-weight: 500;
  }

  .instructions button:hover {
    background-color: #000;
    color: #fff;
  }

  .funds {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    text-align: center;
    color: #000;
  }

  .fund1 {
    grid-area: 1 / 1 / 2 / 2;
    padding: 10px;
    background-color: #9ecdc3;
  }

  .fund1 img {
    width: 180px;
    height: auto;
  }

  .fund1 h3 {
    margin-top:15px;
  }

  .fund1 button {
    margin-top:0;
    margin-bottom: 20px;
  }

  .fund2 {
    grid-area: 1 / 2 / 2 / 3;
    padding: 10px;
    background-color: #9ecdc3;
  }

  .fund2 img {
    width: 180px;
    height: auto;
  }

  .fund2 h3 {
    margin-top:15px;
  }

  .fund2 button {
    margin-top:0;
  }

  .fund3 {
    grid-area: 2 / 1 / 3 / 2;
    padding: 10px;
    background-color: #9ecdc3;
  }

  .fund3 img {
    width: 180px;
    height: auto;
  }

  .fund3 h3 {
    margin-top:15px;
  }

  .fund3 button {
    margin-top:0;
  }

  .fund4 {
    grid-area: 2 / 2 / 3 / 3;
    padding: 10px;
    background-color: #9ecdc3;
  }

  .fund-spacer-main {
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: #000;
  }

  .fund-main-button button {
    background-color: #fff;
    border: 3px solid #fff;
    color: #000;
  }

  .fund4 img {
    width: 180px;
    height: auto;
  }

  .fund4 h3 {
    margin-top:15px;
  }

  .fund4 button {
    margin-top:0;
  }

  .instructions h1 {
    font-size: 3.7rem;
  }

  .all-funds button {
    border: 1px solid #9ecdc3;
  }
  .all-funds button a:hover {
    border: 1px solid #9ecdc3;
    background-color: #9ecdc3;
    color: #000;
    
  }
  .all-funds a {
    text-decoration:none;
  }

  .all-funds:hover {
    color: #000;
  }

  .instructions .copy h3 {
    text-transform: uppercase;
  }

  .instructions .copy p {
    font-weight: 500;
  }

  .top {
    width: 100%;
    height: auto;
    display: block;
  }

  @media only screen and (max-width: 1000px) {
    .instructions .copy h1 {
      font-size: 3rem;
    }
  }

  @media only screen and (max-width: 850px) {
    .funds {
      display: block;
      margin: 0 auto;
      max-width: 400px;
    }

    .instructions {
      position: relative;
      top: 20vh;
    }

    .instructions .copy p {
      margin-bottom: 50px;
    }

    .instructions {
      display: block;
    }

    .fund2 {
      margin-top: 20px;
    }

    .fund3 {
      margin-top: 20px;
    }

    .fund4 {
      margin-top: 20px;
    }

    .fund-spacer {
      margin-bottom: 20px;
    }

    .fund-spacer-main {
      padding-bottom: 20px;
      background-color: transparent;
    }
  }

  @media only screen and (max-width: 500px) {
    .instructions .copy h1 {
      font-size: 2rem;
    }
  }